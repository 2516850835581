.r-container {
  height: fit-content;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.r-slider {
  position: relative;
}

.r-slider-track,
.r-slider-range,
.r-slider-left-value,
.r-slider-right-value {
  position: absolute;
}

.r-slider-track,
.r-slider-range {
  border-radius: 3px;
  height: 5px;
}

.r-slider-track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.r-slider-range {
  background-color: #0084fe;
  z-index: 2;
}

.r-slider-left-value,
.r-slider-right-value {
  color: black;
  font-size: 12px;
  margin: 20px;
}

.r-slider-left-value {
  left: 6px;
}

.r-slider-right-value {
  right: -4px;
}

/* Removing the default appearance */
.r-thumb,
.r-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0r-3s ease, box-shadow 0r-3s ease;
}

.r-thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 200px;
  outline: none;
}

.r-thumb-left {
  z-index: 3;
}

.r-thumb-right {
  z-index: 4;
}

/* For Chrome browsers */
.r-thumb::-webkit-slider-thumb {
  background-color: #0084fe;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  /* box-shadow: 0 0 1px 1px #0084fe; */
}

/* For Firefox browsers */
.r-thumb::-moz-range-thumb {
  background-color: #0084fe;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
  /* box-shadow: 0 0 1px 1px #0084fe; */
}
